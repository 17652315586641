body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

iframe a {
  pointer-events: none;
}

.p-right {
  padding-right: 4px;
}

.p-left {
  padding-left: 4px;
}

.root {
  height: calc(
    var(--vh, 1vh) * 100
  ); /* Use vh as a fallback for browsers that do not support Custom Properties */
  position: relative;
  display: flex;
}

.page {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
}

.container {
  overflow: auto;
  position: relative;
  min-height: min-content;
  flex-grow: 1;
  display: flex;
}

.padding {
  padding: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.noPadding {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.cardHeader {
  padding: 25px;
  position: absolute;
  margin-top: -50px;
  margin-left: -5px;
}

.card {
  padding: 20px;
  margin-top: 40px;
  position: relative;
}

.dotted {
  background-image: -webkit-repeating-radial-gradient(
    1px 1px,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    1px 1px,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    1px 1px,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: repeating-radial-gradient(
    top left,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 100%
  );
  -webkit-background-size: 20px 20px;
  -moz-background-size: 20px 20px;
  background-size: 20px 20px;
}

.refID {
  background: white;
  font-size: 10px;
  padding: 2px;
  color: #001e3c;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.absolute {
  position: absolute;
  top: 16px;
  right: -1px;
}

.lined {
  border: solid 1px rgba(155, 155, 155, 0.3);
}

.MuiBackdrop-root {
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(5px);
  background: rgba(0, 30, 60, 0.7) !important;
}

.pinkBorder {
  border: solid 1px rgba(233, 30, 99, 0.3);
  background: rgba(233, 30, 99, 0.1);
  color: #e91e63;
  padding: 2px 4px;
  border-radius: 4px;
}
